export const CategoryColors = [
  { color: "#EF2139", bgColor: "#FFE9E9" },
  { color: "#4A89E8", bgColor: "#D6E6FF" },
  { color: "#8B79F9", bgColor: "#E8E4FF" },
  { color: "#0CC773", bgColor: "#E0FFF1" },
  { color: "#EC8050", bgColor: "#FFEEE7" },

  { color: "#FF7C00", bgColor: "#FDDCC5" },
  { color: "#2793ff", bgColor: "#EFF5FB" },
  { color: "#c00", bgColor: "#F7EFF4" },
  { color: "#46c5cc", bgColor: "#dbfdff" },
  { color: "#b8ae00", bgColor: "#FEFBC3" },
  { color: "#1bd1d4", bgColor: "#c7feff" }
];

const SPACE_DATA = {
  "u6979777": [{ space_js: "//yfcpsb.yuncsnet.com/common/fa_z/source/ibi/bb/b.js", space_src: "baidujs", space_type: "inters", space_id: "u6979777" }],
  "u6979778": [{ space_js: "//yfcpsb.yuncsnet.com/site/g-baj/source/c/openjs/jcc-d.js", space_src: "baidujs", space_type: "native", space_id: "u6979778" }],
  "u6979779": [{ space_js: "//yfcpsb.yuncsnet.com/site/h/production/cbk/source/d-kd/dk.js", space_src: "baidujs", space_type: "native", space_id: "u6979779" }],
  "u6979780": [{ space_js: "//yfcpsb.yuncsnet.com/source/yt/s_bu/openjs/buv_z.js", space_src: "baidujs", space_type: "native", space_id: "u6979780" }],
  "u6979781": [{ space_js: "//yfcpsb.yuncsnet.com/source/a/production/v-ud-w/resource/dwxe.js", space_src: "baidujs", space_type: "native", space_id: "u6979781" }],
  "u6979782": [{ space_js: "//yfcpsb.yuncsnet.com/source/bw_vex/ex/static/y_l.js", space_src: "baidujs", space_type: "inters", space_id: "u6979782" }],
  "u6979783": [{ space_js: "//yfcpsb.yuncsnet.com/source/c/common/xw_f/openjs/y/production/fyzp.js", space_src: "baidujs", space_type: "native", space_id: "u6979783" }],
  "u6979784": [{ space_js: "//yfcpsb.yuncsnet.com/site/dy-xg/source/zgz/au.js", space_src: "baidujs", space_type: "native", space_id: "u6979784" }],
  "u6979785": [{ space_js: "//yfcpsb.yuncsnet.com/site/ezyh_ah/resource/a/b/w.js", space_src: "baidujs", space_type: "native", space_id: "u6979785" }],
  "u6979786": [{ space_js: "//yfcpsb.yuncsnet.com/common/fazi/openjs/b_ib_c_z.js", space_src: "baidujs", space_type: "native", space_id: "u6979786" }],
};
export const SPACE_MAP = {
  HomeInter: SPACE_DATA["u6979777"],
  HomeBanner: SPACE_DATA["u6979778"],
  HomeNative: SPACE_DATA["u6979779"],
  HomeNativeCenter: SPACE_DATA["u6979780"],
  HomeNativeBottom: SPACE_DATA["u6979781"],
  HotInter: SPACE_DATA["u6979777"],
  HotBanner: SPACE_DATA["u6979778"],
  HotNative: SPACE_DATA["u6979779"],
  DetailInter: SPACE_DATA["u6979777"],
  DetailBanner: SPACE_DATA["u6979778"],
  DetailNative: SPACE_DATA["u6979779"],
}
export const ALL_SPACE_MAP = {
  H5_NXRJ_OP_1: {
    HomeInter: SPACE_DATA["u6979777"],
    HomeBanner: SPACE_DATA["u6979778"],
    HomeNative: SPACE_DATA["u6979779"],
    HomeNativeCenter: SPACE_DATA["u6979780"],
    HomeNativeBottom: SPACE_DATA["u6979781"],
    HotInter: SPACE_DATA["u6979777"],
    HotBanner: SPACE_DATA["u6979778"],
    HotNative: SPACE_DATA["u6979779"],
    DetailInter: SPACE_DATA["u6979777"],
    DetailBanner: SPACE_DATA["u6979778"],
    DetailNative: SPACE_DATA["u6979779"],
  },
  H5_NXRJ_OP_2: {
    HomeInter: SPACE_DATA["u6979782"],
    HomeBanner: SPACE_DATA["u6979783"],
    HomeNative: SPACE_DATA["u6979784"],
    HomeNativeCenter: SPACE_DATA["u6979785"],
    HomeNativeBottom: SPACE_DATA["u6979786"],
    HotInter: SPACE_DATA["u6979782"],
    HotBanner: SPACE_DATA["u6979783"],
    HotNative: SPACE_DATA["u6979784"],
    DetailInter: SPACE_DATA["u6979782"],
    DetailBanner: SPACE_DATA["u6979783"],
    DetailNative: SPACE_DATA["u6979784"],
  },
  DEFAULT: {
    HomeInter: SPACE_DATA["u6979777"],
    HomeBanner: SPACE_DATA["u6979778"],
    HomeNative: SPACE_DATA["u6979779"],
    HomeNativeCenter: SPACE_DATA["u6979780"],
    HomeNativeBottom: SPACE_DATA["u6979781"],
    HotInter: SPACE_DATA["u6979777"],
    HotBanner: SPACE_DATA["u6979778"],
    HotNative: SPACE_DATA["u6979779"],
    DetailInter: SPACE_DATA["u6979777"],
    DetailBanner: SPACE_DATA["u6979778"],
    DetailNative: SPACE_DATA["u6979779"],
  },
};
